import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

const url = '/rejection-reasons'

export function getRejectionReasonsList() {
  return axios.get(url)
}

export function addNewReason(name: string) {
  return axios.post(url, { name })
}

export function deleteRejectionReason(id: idType) {
  return axios.delete(url + '/' + id)
}
