import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { idType } from '../../interfaces/types/types'
import { getAllApplications, getApplicationById } from '../../api/applications'
import { IApplicationsResponseObject } from '../../interfaces'

export const APPLICATIONS_KEY = 'APPLICATIONS_KEY'

export function useApplications(options?: UseQueryOptions<IApplicationsResponseObject[], Error>) {
  return useQuery<IApplicationsResponseObject[], Error>(
    APPLICATIONS_KEY,
    () =>
      getAllApplications().then(
        (response: AxiosResponse<IApplicationsResponseObject[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useApplication(id: idType, options?: UseQueryOptions<any, Error>) {
  return useQuery<any, Error>(
    [APPLICATIONS_KEY, id],
    () => getApplicationById(id).then((response: AxiosResponse<any>) => response.data),
    {
      ...options,
    },
  )
}
