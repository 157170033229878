import { idType } from '../../../../../../interfaces/types/types'
import { Button, message } from 'antd'
import { useMutation } from 'react-query'
import { paymentApproved } from '../../../../../../api/applications'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import { WalletOutlined } from '@ant-design/icons'
import React from 'react'
import { AxiosError } from 'axios'

interface props {
  id: idType
}

const ApprovePayment: React.FC<props> = ({ id }) => {
  const { mutate, isLoading } = useMutation(paymentApproved, {
    onSuccess: () => {
      message.success('Статус успешно изменен')
      queryClient.invalidateQueries(APPLICATIONS_KEY)
    },
    onError: (error: AxiosError) => {
      message.error(error.response?.data.message)
    },
  })
  const handleApprove = () => mutate(id)

  return (
    <Button
      size={'small'}
      icon={<WalletOutlined />}
      type={'link'}
      block
      onClick={handleApprove}
      loading={isLoading}
      disabled={isLoading}
    >
      Подтвердить оплату
    </Button>
  )
}

export default ApprovePayment
