import { useQuery, UseQueryOptions } from 'react-query'
import { ISource } from '../../interfaces'
import { AxiosResponse } from 'axios'
import { getAllSources } from '../../api/sources'

export const SOURCES_KEY = 'sources-key'

export function useAllSources(options?: UseQueryOptions<ISource[], Error>) {
  return useQuery<ISource[], Error>(
    SOURCES_KEY,
    () => getAllSources().then((response: AxiosResponse<ISource[]>) => response.data),
    {
      ...options,
    },
  )
}
