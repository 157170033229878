import React from 'react'
import { LeadsStatistic } from '../../../app/modules/new-leads/leads-statistic/leads-statistic'

interface props {}

export const LeadsStatisticPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <LeadsStatistic />
    </React.Fragment>
  )
}
