import React from 'react'
import styles from './color-block.module.scss'
import { Tooltip } from 'antd'

interface props {
  color: string
}

const ColorBlock: React.FC<props> = ({ color }) => {
  const style = { background: color }
  return (
    <Tooltip title={color} color={color}>
      <div className={styles.block} style={style} />
    </Tooltip>
  )
}

export default ColorBlock
