import React from 'react'
import NewLeads from "../../app/modules/new-leads/new-leads";

interface props {}

const NewLeadsPage: React.FC<props> = () => {
    return (
        <React.Fragment>
            <NewLeads />
        </React.Fragment>
    )
}

export default NewLeadsPage
