import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { getRejectionReasonsList } from '../../api/rejection-reasons'
import { IApplicationRejectReason } from '../../interfaces'

export const REJECTION_REASONS_LIST = 'rejection-reasons-list-key'

export function useRejectionReasonsList(
  options?: UseQueryOptions<IApplicationRejectReason[], Error>,
) {
  return useQuery<IApplicationRejectReason[], Error>(
    REJECTION_REASONS_LIST,
    () =>
      getRejectionReasonsList().then(
        (response: AxiosResponse<IApplicationRejectReason[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}
