import {
  REJECTION_REASONS_LIST,
  useRejectionReasonsList,
} from '../../../../../hooks/query/rejection-reasons'
import { PageHeader, Skeleton, Space, Table } from 'antd'
import ErrorAlert from '../../../../core/error-alert/error-alert'
import { ColumnType } from 'antd/lib/table'
import { IApplicationRejectReason } from '../../../../../interfaces'
import DeleteModal from '../../../../core/delete-modal/delete-modal'
import { deleteRejectionReason } from '../../../../../api/rejection-reasons'

interface props {}

export const RejectDictionary: React.FC<props> = () => {
  const { data, isLoading, isError, error } = useRejectionReasonsList()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const columns: ColumnType<IApplicationRejectReason>[] = [
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'name',
      title: 'Название',
    },
    {
      dataIndex: 'description',
      title: 'Описание',
    },
  ]
  return (
    <div>
      <PageHeader title={'Справочник причин отказов'} />
      <Table rowKey={'id'} dataSource={data} columns={columns} />
    </div>
  )
}
