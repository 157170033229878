import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

const url = '/rates'

export function createTeacherSalaryHistory(data: {
  teacher_id: idType
  date: string
  percentage: number
  salary: number
  payment_per_student: number
}) {
  return axios.post(url, data)
}

export function updateTeacherSalaryHistory(data: {
  rateId: idType
  percentage: number
  salary: number
  payment_per_student: number
}) {
  return axios.patch(url + '/' + data.rateId, {
    percentage: data.percentage,
    salary: data.salary,
    payment_per_student: data.payment_per_student,
  })
}
