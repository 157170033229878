import React, { useState } from 'react'
import { Button, Form, Input, message, Modal, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import FormCoursesList from '../../../../../core/form-courses-list/form-courses-list'
import { IApplication } from '../../../../../../interfaces'
import { idType } from '../../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { updateApplication } from '../../../../../../api/applications'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import FormGroupsList from '../../../../../core/form-groups-list/form-groups-list'
import BranchesList from '../../../../../core/form-group/branches-list/branches-list'
import FormEnrollmentsList from '../../../../../core/form-enrollments-list/form-enrollments-list'

interface props {
  application: IApplication
}

type Fields = {
  course_id: idType
  group_id: idType
  id: idType
}
const EditApplication: React.FC<props> = ({ application }) => {
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  const { isLoading, mutate } = useMutation(updateApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries(APPLICATIONS_KEY)
      message.success('Заявка успешно изменена')
      handleChangeVisible()
    },
    onError: () => {
      message.error('Ошибка изменения заявки')
    },
  })

  const onFinish = (values: Fields) => mutate(values)

  return (
    <React.Fragment>
      <Button
        size={'small'}
        onClick={handleChangeVisible}
        icon={<EditOutlined />}
        block
        type={'link'}
      >
        Изменить заявку
      </Button>
      <Modal destroyOnClose visible={visible} onCancel={handleChangeVisible} footer={false}>
        <Typography.Title level={4}>Изменить заявку</Typography.Title>
        <Form
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={{
            course_id: application.course_id,
            id: application.id,
            group_id: application.group_id,
            enrollment_id: application.enrollment_id,
          }}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <FormEnrollmentsList name={'enrollment_id'} />
          <FormCoursesList name={'course_id'} label={'Курс'} required={true} />
          <FormGroupsList
            name={'group_id'}
            label={'Выберите группу'}
            mode={'single'}
            required={false}
          />
          <BranchesList mode={'single'} required={false} />
          <Form.Item>
            <Button
              type={'primary'}
              block
              disabled={isLoading}
              loading={isLoading}
              htmlType={'submit'}
            >
              Отправить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default EditApplication
