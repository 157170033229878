import { request as axios } from './index'
import { ISalesFunnelInfo } from '../interfaces'

const url = '/analytics'

export function salesFunnel(params: {
  start_date: string | undefined
  end_date: string | undefined
  branch_id: string | undefined
}) {
  return axios.get<ISalesFunnelInfo[]>(url + '/sales-funnel', { params })
}
