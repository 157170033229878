import React from 'react'
import styles from './leads-column.module.scss'
import { IApplicationsResponseObject } from '../../../../../interfaces'
import LeadsBids from '../../leads-bids/leads-bids'
import { Typography } from 'antd'

interface props {
  column: IApplicationsResponseObject
}

const LeadsColumn: React.FC<props> = ({ column }) => {
  const { name, applications, description, id } = column

  return (
    <div className={styles.column}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{name}</h2>
        <Typography.Text type={'secondary'}>{description}</Typography.Text>
      </div>

      <div className={styles.droppable}>
        <LeadsBids bids={applications} />
      </div>
    </div>
  )
}

export default React.memo(LeadsColumn)
