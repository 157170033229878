import React from 'react'
import { idType } from '../../../../../../interfaces/types/types'
import { Button, message } from 'antd'
import { useMutation } from 'react-query'
import { trialEnroll } from '../../../../../../api/applications'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import { WarningOutlined } from '@ant-design/icons'

interface ITrialEnrolledProps {
  id: idType
}
export const TrialEnrolled: React.FC<ITrialEnrolledProps> = ({ id }) => {
  const { mutate, isLoading } = useMutation(trialEnroll, {
    onSuccess: () => {
      message.success('Статус успешно изменен')
      queryClient.invalidateQueries(APPLICATIONS_KEY)
    },
    onError: () => {
      message.error('Ошибка изменения статуса! Попробуйте позже')
    },
  })
  const handleEnroll = () => mutate(id)

  return (
    <Button
      size={'small'}
      type={'link'}
      block
      onClick={handleEnroll}
      loading={isLoading}
      icon={<WarningOutlined />}
      disabled={isLoading}
    >
      Записать на пробный урок
    </Button>
  )
}
