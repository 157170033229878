import React from 'react'
import styles from './leads-bid-dropdown.module.scss'
import { Dropdown, Menu, Typography } from 'antd'
import { IApplication, IApplicationActionEvent } from '../../../../../../interfaces'
import { LeadsBidStartProcess } from '../leads-bid-start-process/leads-bid-start-process'
import EditApplication from '../edit-application/edit-application'
import { TrialEnrolled } from '../trial-enrolled/trial-enrolled'
import { TrialAttend } from '../trial-attend/trial-attend'
import { PaymentRequested } from '../payment-requested/payment-requested'
import ApplicationEnrolled from '../application-enrolled/application-enrolled'
import RejectApplication from '../reject-application/reject-application'
import WithdrawanApplication from '../withdrawan-application/withdrawan-application'
import ApprovePayment from '../approve-payment/approve-payment'
import AddPayment from '../add-payment/add-payment'

interface props {
  item: IApplication
}

const LeadsBidDropdown: React.FC<props> = ({ item }) => {
  const actions: Record<IApplicationActionEvent, any> = {
    'applications/{id}/start-processing': {
      render: <LeadsBidStartProcess id={item.id} />,
    },
    'applications/{id}/edit': {
      render: <EditApplication application={item} />,
    },
    'applications/{id}/trial-enrolled': {
      render: <TrialEnrolled id={item.id} />,
    },
    'applications/{id}/trial-attended': {
      render: <TrialAttend id={item.id} />,
    },
    'applications/{id}/payment-requested': {
      render: <PaymentRequested id={item.id} />,
    },
    'applications/{id}/pay': {
      render: <AddPayment application={item} />,
    },
    'applications/{id}/enrolled': {
      render: (
        <ApplicationEnrolled id={item.id} course_id={item.course_id} group_id={item.group_id} />
      ),
    },
    'applications/{id}/reject': {
      render: <RejectApplication id={item.id} />,
    },
    'applications/{id}/withdrawn': {
      render: <WithdrawanApplication id={item.id} />,
    },
    'applications/{id}/paid': {
      render: <ApprovePayment id={item.id} />,
    },
  }

  const menu = (
    <Menu>
      {item.status.actions.map(action => (
        <Menu.Item key={action.id}>{actions[action.event]?.render}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement={'bottomLeft'} trigger={['click']}>
      <div className={styles.actions}>
        <Typography.Text strong className={styles.text}>
          Действия с заявкой
        </Typography.Text>
      </div>
    </Dropdown>
  )
}

export default LeadsBidDropdown
