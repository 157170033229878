import React from 'react'
import { useParams } from 'react-router-dom'
import ErrorAlert from '../../core/error-alert/error-alert'
import { Col, PageHeader, Row, Skeleton, Space } from 'antd'
import StudentCard from './student-card/student-card'
import { useTranslation } from 'react-i18next'
import StudentsDrawer from './students-drawer/students-drawer'
import Head from '../../../hoc/head/head'
import AdditonalInfos from '../../core/additonal-infos/additonal-infos'
import { useStudent } from '../../../hooks/query/students'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import StudentTabs from './student-tabs/student-tabs'

interface props {}

const Student: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])
  const { t } = useTranslation(['translation', 'students'])

  const { isError, isLoading, error, data } = useStudent(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const student = data!

  return (
    <Head title={student.name}>
      <PageHeader
        title={student.name}
        subTitle={t('students:studentSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          access && (
            <Space key={'1'}>
              <StudentsDrawer updateOne={true} title={'editTitle'} student={student} />
            </Space>
          ),
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={12}>
          <StudentCard student={student} />
        </Col>
        <Col xs={24} lg={12}>
          <AdditonalInfos additionalInfos={student.additional_infos} />
        </Col>
        <Col xs={24} lg={24}>
          <StudentTabs id={student.id} name={student.name} />
        </Col>
      </Row>
    </Head>
  )
}

export default Student
