import React, { useState } from 'react'
import { Button, Drawer, Tabs } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import Sources from './sources/sources'
import { RejectDictionary } from './rejection-dictionary/rejection-dictionary'
import styles from './leads-settings.module.scss'

const { TabPane } = Tabs

interface props {}

const LeadsSettings: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const hideDrawer = () => setVisible(false)
  const showDrawer = () => setVisible(true)

  return (
    <>
      <Button onClick={showDrawer} type={'primary'} icon={<SettingOutlined />} />
      <Drawer visible={visible} onClose={hideDrawer} title={'Настройки'} width={'100%'}>
        <Tabs className={styles.tabs} tabPosition={'left'} style={{ height: '100%' }}>
          <TabPane tab={'Источники'} key="sources">
            <Sources />
          </TabPane>
          <TabPane tab="Справочник отказов" key="rejection-dictionary">
            <RejectDictionary />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  )
}

export default LeadsSettings
