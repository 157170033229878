import React from 'react'
import { Button, message } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { startProcess } from '../../../../../../api/applications'
import { idType } from '../../../../../../interfaces/types/types'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'

interface ILeadsBidStartProcessProps {
  id: idType
}
export const LeadsBidStartProcess: React.FC<ILeadsBidStartProcessProps> = ({ id }) => {
  const { isLoading, mutate } = useMutation(startProcess, {
    onSuccess: () => {
      queryClient.invalidateQueries(APPLICATIONS_KEY)
      message.success('Статус успешно изменен')
    },
    onError: () => {
      message.error('Ошибка изменения статуса! Повторите попытку позже')
    },
  })

  const handleStartProcess = () => mutate(id)

  return (
    <Button
      type={'link'}
      size={'small'}
      block
      icon={<PlayCircleOutlined />}
      onClick={handleStartProcess}
      disabled={isLoading}
      loading={isLoading}
    >
      Обработать заявку
    </Button>
  )
}
