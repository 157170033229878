import React from 'react'
import { Form, Radio, Space, Spin } from 'antd'
import { useRejectionReasonsList } from '../../../../hooks/query/rejection-reasons'
import ErrorAlert from '../../error-alert/error-alert'

interface props {
  name?: string
  required?: boolean
}

export const RejectionReasonsList: React.FC<props> = ({ name, required }) => {
  const { data, isLoading, isError, error } = useRejectionReasonsList()

  if (isError) return <ErrorAlert msg={error?.message} />

  return (
    <Spin spinning={isLoading}>
      <Form.Item name={name} label={'Причина отказа студентом'} rules={[{ required }]}>
        <Radio.Group>
          <Space direction="vertical">
            {data?.map(reason => (
              <Radio value={reason.id}>{reason.name}</Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </Spin>
  )
}
