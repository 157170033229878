import React from 'react'
import MyTable from '../../../core/my-table/my-table'
import { IRate, ITeacher, IUser } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../interfaces/types/types'
import { Link } from 'react-router-dom'
import TeachersAction from '../teachers-action/teachers-action'
import StatusTag from '../../../core/status-tag/status-tag'
import PhoneNumber from '../../../core/phone-number/phone-number'
import TeacherRate from '../teacher-rate/teacher-rate'
import { TEACHERS_KEY } from '../../../../hooks/query/teachers'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { ColumnsType } from 'antd/es/table'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import DateWithMonth from '../../../core/date-with-month/date-with-month'

interface props {
  teachers: ITeacher[]
}

const TeachersTable: React.FC<props> = ({ teachers }) => {
  const { t } = useTranslation()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  const columns: ColumnsType<ITeacher> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('firstName'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (text: string, record: IUser) => (
        <>
          <TableMobileTitle title={t('firstName')} />
          <Link to={`/teachers/details/${record.id}`}>{text}</Link>
        </>
      ),
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('phone')} />
          <PhoneNumber phone={text} />
        </>
      ),
    },
    {
      title: t('salary'),
      dataIndex: 'rate',
      key: 'salary',
      sorter: true,
      render: (rate: IRate) => (
        <>
          <TableMobileTitle title={t('salary')} />
          <TeacherRate value={rate.salary} sum={true} />
        </>
      ),
    },
    {
      title: t('percentage'),
      dataIndex: 'rate',
      key: 'percentage',
      sorter: true,
      render: (rate: IRate) => (
        <>
          <TableMobileTitle title={t('percentage')} />
          <TeacherRate value={rate.percentage} />
        </>
      ),
    },
    {
      title: 'Фикс со студента',
      dataIndex: 'rate',
      key: 'payment_per_student',
      sorter: true,
      render: (rate: IRate) => (
        <>
          <TableMobileTitle title={'Фикс со студента'} />
          <TeacherRate value={rate.payment_per_student} sum={true} />
        </>
      ),
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('createdAt')} />
          <DateWithMonth date={text} />
        </>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text: number) => (
        <>
          <TableMobileTitle title={t('status')} />
          <StatusTag status={text} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      className: access ? 'show' : 'hide',
      render: (record: ITeacher) => <TeachersAction teacher={record} />,
    },
  ]

  return <MyTable fetchingQueryKey={TEACHERS_KEY} columns={columns} data={teachers} />
}

export default React.memo(TeachersTable)
