import React, { useState } from 'react'
import { Drawer, Form, Input, message } from 'antd'
import DrawerOpen from '../../../../../core/drawer/drawer-open/drawer-open'
import { useTranslation } from 'react-i18next'
import { drawerSettings } from '../../../../../../lib/settings'
import DrawerFooter from '../../../../../core/drawer/drawer-footer/drawer-footer'
import { ICourse } from '../../../../../../interfaces'
import { useMutation } from 'react-query'
import { createSource, updateSource } from '../../../../../../api/sources'
import { queryClient } from '../../../../../../index'
import { SOURCES_KEY } from '../../../../../../hooks/query/sources'
import ColorPicker from '../../../../../core/form-group/color-picker/color-picker'

interface props {
  source?: ICourse
}

const SourcesDrawer: React.FC<props> = ({ source }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const handleVisibleChange = () => setVisible(!visible)
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const title = source ? t('edit') : t('add')
  const formId = 'add-source-form'

  const onSuccess = () => {
    message.success('Операция прошла успешно')
    queryClient.invalidateQueries(SOURCES_KEY)
    handleVisibleChange()
  }
  const onError = () => {
    message.error('Произошла ошибка! Попробуйте снова')
  }
  const create = useMutation(createSource, { onSuccess, onError })
  const update = useMutation(updateSource, { onSuccess, onError })

  const onFinish = (data: ICourse) => (source ? update.mutate(data) : create.mutate(data))

  return (
    <>
      <DrawerOpen drawerToggle={handleVisibleChange} edit={!!source} />
      <Drawer
        {...drawerSettings}
        title={title}
        visible={visible}
        onClose={handleVisibleChange}
        footer={
          <DrawerFooter drawerToggle={handleVisibleChange} formId={formId} disabled={false} />
        }
      >
        <Form
          onFinish={onFinish}
          form={form}
          id={formId}
          layout={'vertical'}
          initialValues={source}
        >
          <Form.Item hidden name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item name={'name'} label={t('name')} rules={[{ required: true }]}>
            <Input placeholder={t('inputName')} />
          </Form.Item>
          <ColorPicker formInstance={form} label={'Цвет'} name={'color'} />
          <Form.Item name={'description'} label={t('description')}>
            <Input.TextArea rows={5} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default SourcesDrawer
