import { request } from './index'
import { idType } from '../interfaces/types/types'

const url = '/sources'

export const getAllSources = () => request.get(url)
export const getSourceById = (id: idType) => request.get(url + '/' + id)
export const createSource = (data: any) => request.post(url, data)
export const updateSource = (data: any) => request.put(url + '/' + data.id, data)
export const deleteSource = (id: idType) => request.delete(url + '/' + id)
