import React, { useEffect } from 'react'
import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css'
import { Button, Card, Col, DatePicker, Divider, Form, Row, Spin } from 'antd'
import { useMutation } from 'react-query'
import { salesFunnel } from '../../../../../api/analytics'
import { FilterOutlined } from '@ant-design/icons'
import moment from 'moment'
import BranchesList from '../../../../core/form-group/branches-list/branches-list'

interface props {}

const SalesFunnel: React.FC<props> = () => {
  const { data, isLoading, mutate } = useMutation(salesFunnel)

  useEffect(() => {
    mutate({ start_date: undefined, end_date: undefined, branch_id: undefined })
  }, [])

  const onFinish = (values: { date: Date[]; branch_id: string }) => {
    if (values.date) {
      const [start_date, end_date] = values.date.map(d => moment(d).format('YYYY-MM-DD'))
      mutate({ start_date, end_date, branch_id: values.branch_id })
    } else {
      mutate({ start_date: undefined, end_date: undefined, branch_id: values.branch_id })
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Card bordered={false}>
        <Form onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item name={'date'}>
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={9}>
              <BranchesList mode={'single'} name={'branch_id'} required={false} label={''} />
            </Col>
            <Col span={3}>
              <Form.Item style={{ width: '100%' }}>
                <Button type={'primary'} htmlType={'submit'} icon={<FilterOutlined />} block />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <FunnelChart
          title={'Воронка продаж'}
          data={data?.data.map(info => ({ name: info.name, value: info.applications_count }))}
        />
      </Card>
    </Spin>
  )
}

export default SalesFunnel
