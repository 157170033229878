import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

const url = '/enrollments'

export function getAllEnrollments() {
  return axios.get(url)
}

export function createEnrollment(data: any) {
  return axios.post(url, data)
}

export function updateEnrollment(data: any) {
  return axios.patch(url + '/' + data.id, data)
}

export function deleteEnrollment(id: idType) {
  return axios.delete(url + '/' + id)
}
