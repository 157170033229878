import React from 'react'
import styles from './leads-columns.module.scss'
import LeadsColumn from './leads-column/leads-column'
import { IApplicationsResponseObject } from '../../../../interfaces'

interface props {
  columns: IApplicationsResponseObject[]
}

const LeadsColumns: React.FC<props> = ({ columns }) => {
  return (
    <div className={styles.wrapper}>
      {columns.map(column => (
        <LeadsColumn key={column.id} column={column} />
      ))}
    </div>
  )
}

export default LeadsColumns
