import React, { useState } from 'react'
import { Alert, Button, Form, Input, message, Modal, Typography } from 'antd'
import { CloseOutlined, EditOutlined, FrownOutlined, WarningOutlined } from '@ant-design/icons'
import { idType } from '../../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { rejectApplication } from '../../../../../../api/applications'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import { RejectionReasonsList } from '../../../../../core/form-group/rejection-reasons-list/rejection-reasons-list'
import { AddRejectReasonInput } from '../../../../../core/form-group/add-reject-reason-input/add-reject-reason-input'

interface props {
  id: idType
}

type Fields = {
  id: idType
  rejection_reason_id: idType
}

const RejectApplication: React.FC<props> = ({ id }) => {
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  const { isLoading, mutate } = useMutation(rejectApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries(APPLICATIONS_KEY)
      message.success('Заявка успешно отклонена')
      handleChangeVisible()
    },
    onError: () => {
      message.error('Ошибка отклонения заявки')
    },
  })

  const onFinish = (values: Fields) => mutate(values)

  return (
    <React.Fragment>
      <Button
        size={'small'}
        onClick={handleChangeVisible}
        icon={<FrownOutlined />}
        block
        type={'link'}
        danger
      >
        Отклонить заявку
      </Button>
      <Modal destroyOnClose visible={visible} onCancel={handleChangeVisible} footer={false}>
        <Typography.Title level={4}>Отклонить заявку</Typography.Title>
        <Form onFinish={onFinish} layout={'vertical'} initialValues={{ id }}>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <RejectionReasonsList name={'rejection_reason_id'} required={true} />
          <Form.Item>
            <Alert
              message={'Добавить новую причину отказа'}
              type={'info'}
              showIcon
              description={<AddRejectReasonInput />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type={'primary'}
              block
              disabled={isLoading}
              loading={isLoading}
              htmlType={'submit'}
            >
              Отправить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default RejectApplication
