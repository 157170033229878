import React from 'react'
import { idType } from '../../../../../../interfaces/types/types'
import { Button, message } from 'antd'
import { useMutation } from 'react-query'
import { paymentRequested } from '../../../../../../api/applications'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import { WalletOutlined } from '@ant-design/icons'

interface IPaymentRequestedProps {
  id: idType
}
export const PaymentRequested: React.FC<IPaymentRequestedProps> = ({ id }) => {
  const { mutate, isLoading } = useMutation(paymentRequested, {
    onSuccess: () => {
      message.success('Статус успешно изменен')
      queryClient.invalidateQueries(APPLICATIONS_KEY)
    },
    onError: () => {
      message.error('Ошибка изменения статуса! Попробуйте позже')
    },
  })
  const handleEnroll = () => mutate(id)

  return (
    <Button
      size={'small'}
      icon={<WalletOutlined />}
      type={'link'}
      block
      onClick={handleEnroll}
      loading={isLoading}
      disabled={isLoading}
    >
      Ожидание оплаты
    </Button>
  )
}
