import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, DatePicker, Divider, Form, message, Modal, Space } from 'antd'
import { UserDeleteOutlined } from '@ant-design/icons'
import { idType } from '../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { excludeStudentFromGroupApi } from '../../../api/groups'
import { queryClient } from '../../../index'
import moment, { Moment } from 'moment'
import { RejectionReasonsList } from '../form-group/rejection-reasons-list/rejection-reasons-list'

interface props {
  groupId: idType
  studentId: idType
  studentName: string
  groupStartDate: string
  groupEndDate: string
  studentAddedAt: string
  excludeStudentQueries: Array<string | string[]>
}

const ToExcludeStudentModal: React.FC<props> = ({
  groupId,
  studentId,
  studentName,
  groupStartDate,
  groupEndDate,
  studentAddedAt,
  excludeStudentQueries,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { t } = useTranslation(['translation', 'groups'])

  const { isLoading, mutateAsync } = useMutation(excludeStudentFromGroupApi, {
    onSuccess: async () => {
      const requests = excludeStudentQueries.map(q => queryClient.invalidateQueries(q))
      Promise.all(requests).finally(() => {
        message.success(t('responseSuccess'))
        modalHandler()
      })
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = ({ date, rejection_reason_id }: any) =>
    mutateAsync({ groupId, studentId, date, rejection_reason_id })

  const disabledDate = (date: Moment) => {
    const startOf =
      moment(studentAddedAt) < moment(groupStartDate) ? groupStartDate : studentAddedAt
    const endOf = moment(groupEndDate) < moment().endOf('month') ? groupEndDate : moment()

    return !date.isBetween(startOf, endOf)
  }

  const title = t('groups:expelStudent')

  return (
    <React.Fragment>
      <button onClick={modalHandler} className={'ant-btn-no-style'}>
        <UserDeleteOutlined /> <span>{title}</span>
      </button>
      <Modal
        visible={visible}
        onCancel={modalHandler}
        destroyOnClose={true}
        title={
          <Space split={<Divider type={'vertical'} />}>
            <span>{title}</span>
            <span>{studentName}</span>
          </Space>
        }
        okText={t('submit')}
        okButtonProps={{ htmlType: 'submit', form: 'exclude-student-form' }}
        confirmLoading={isLoading}
        centered
      >
        <Alert message={t('groups:expelStudentMessage')} showIcon={false} type={'warning'} banner />
        <Divider />
        <Form id={'exclude-student-form'} onFinish={onFinish} layout={'vertical'}>
          <Form.Item
            rules={[{ required: true, message: t('required') }]}
            name={'date'}
            label={t('groups:excludeLabel')}
          >
            <DatePicker
              format={'MMMM'}
              picker={'month'}
              style={{ width: '100%' }}
              allowClear={false}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <RejectionReasonsList name={'rejection_reason_id'} required={true} />
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default ToExcludeStudentModal
