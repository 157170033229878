import React from 'react'
import { Form, InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
  min?: number
}

const PercentageInput: React.FC<props> = ({ name = 'percentage', label, min = 0 }) => {
  const { t } = useTranslation()

  const width = { width: '100%' }
  return (
    <Form.Item name={name} label={label} rules={[{ required: true }]}>
      <InputNumber style={width} placeholder={t('inputPercentage')} max={100} min={min} />
    </Form.Item>
  )
}

export default PercentageInput
