import React, { ChangeEvent, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { createLead, searchLead } from '../../../../../../api/leads'
import { createApplication } from '../../../../../../api/applications'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import { Button, Card, Form, Input, message, Spin, Typography } from 'antd'
import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons'
import { SelectSource } from '../../../../../core/form-group/select-source/select-source'
import FormCoursesList from '../../../../../core/form-courses-list/form-courses-list'
import { idType } from '../../../../../../interfaces/types/types'
import FormGroupsList from '../../../../../core/form-groups-list/form-groups-list'
import FormEnrollmentsList from '../../../../../core/form-enrollments-list/form-enrollments-list'

interface ICreateApplicationFormProps {
  onSuccess?: () => void
}

type Fields = {
  lead_id: idType
  source_id: idType
  name: string
  phone: string
  course_id: string
  enrollment_id: idType
}

export const CreateApplicationForm: React.FC<ICreateApplicationFormProps> = ({ onSuccess }) => {
  const [phone, setPhone] = useState('')
  const handleSetPhone = (e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)

  const { mutate, data, isLoading } = useMutation(searchLead)

  const createLeadMutation = useMutation(createLead)

  const createApplicationMutation = useMutation(createApplication, {
    onSuccess: () => {
      onSuccess?.()
      setPhone('')
      queryClient.invalidateQueries(APPLICATIONS_KEY)
      message.success('Заявка была успешно создана')
    },
    onError: () => {
      message.error('Ошибка создания заявки! Попробуйте снова')
    },
  })

  useEffect(() => {
    if (phone.length === 9) {
      mutate(phone)
    }
  }, [phone])

  const onFinish = (values: Fields) => {
    if (data?.data.data.length) {
      const lead = data.data.data[0]
      createApplicationMutation.mutate({
        course_id: values.course_id,
        enrollment_id: values.enrollment_id,
        lead_id: lead.id,
      })
    } else {
      createLeadMutation
        .mutateAsync({
          name: values.name,
          phone: values.phone,
          source_id: values.source_id,
        })
        .then(response => {
          if (response.status === 201) {
            createApplicationMutation.mutate({
              lead_id: response.data.lead.id,
              course_id: values.course_id,
              enrollment_id: values.enrollment_id,
            })
          }
        })
    }
  }

  const isCreateLoading = createApplicationMutation.isLoading || createLeadMutation.isLoading

  return (
    <Spin spinning={isLoading}>
      <Form onFinish={onFinish} layout={'vertical'}>
        <Form.Item name={'lead_id'} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name={'phone'}
          label={'Номер телефона'}
          rules={[{ min: 9, max: 9, message: 'Длина номера должна составлять 9 символов' }]}
        >
          <Input
            onChange={handleSetPhone}
            value={phone}
            addonBefore={'+998'}
            placeholder={'Номер телефона'}
            maxLength={9}
          />
        </Form.Item>

        {data?.data.data.length ? (
          <div
            style={{
              background: '#fafafa',
              padding: '15px 10px',
              borderRadius: '5px',
              marginBottom: 12,
            }}
          >
            <Typography.Title level={5}>
              <UserOutlined /> Лид
            </Typography.Title>
            <Card size={'small'} bordered={false}>
              <div>
                <Typography.Text>
                  <strong>Имя:</strong> {data?.data.data[0].name}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text>
                  <strong>Источник:</strong> {data?.data.data[0].source?.name}
                </Typography.Text>
              </div>
            </Card>
          </div>
        ) : (
          <React.Fragment>
            <Form.Item name={'name'} label={'ФИО студента'} rules={[{ required: true }]}>
              <Input placeholder={'Введите ФИО'} addonBefore={<UserOutlined />} />
            </Form.Item>
            <SelectSource name={'source_id'} label={'Источник'} required={true} />
          </React.Fragment>
        )}

        <FormEnrollmentsList name={'enrollment_id'} />
        <FormCoursesList required name={'course_id'} label={'Курс'} />
        <FormGroupsList
          name={'group_id'}
          label={'Выберите группу'}
          mode={'single'}
          required={false}
        />

        <Form.Item>
          <Button
            htmlType={'submit'}
            type={'primary'}
            icon={<PlusCircleOutlined />}
            block
            disabled={isCreateLoading}
            loading={isCreateLoading}
          >
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}
