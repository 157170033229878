import React, { useState } from 'react'
import { Button, Form, Input, message, Modal, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import FormCoursesList from '../../../../../core/form-courses-list/form-courses-list'
import { idType } from '../../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { enrollApplication } from '../../../../../../api/applications'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import FormGroupsList from '../../../../../core/form-groups-list/form-groups-list'
import MyDatePicker from '../../../../../core/form-group/my-date-picker/my-date-picker'
import moment from 'moment'

interface props {
  course_id: idType
  group_id: idType
  id: idType
}

type Fields = {
  course_id: idType
  group_id: idType
  added_at: string
  id: idType
}

const ApplicationEnrolled: React.FC<props> = ({ id, course_id, group_id }) => {
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  const { isLoading, mutate } = useMutation(enrollApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries(APPLICATIONS_KEY)
      message.success('Заявка успешно изменена')
      handleChangeVisible()
    },
    onError: () => {
      message.error('Ошибка изменения заявки')
    },
  })

  const onFinish = (values: Fields) =>
    mutate({
      course_id: values.course_id,
      group_id: values.group_id,
      id: values.id,
      added_at: moment(values.added_at).format('YYYY-MM-DD'),
    })

  return (
    <React.Fragment>
      <Button
        size={'small'}
        onClick={handleChangeVisible}
        icon={<CheckCircleOutlined />}
        block
        type={'link'}
      >
        Зачислить на курс
      </Button>
      <Modal destroyOnClose visible={visible} onCancel={handleChangeVisible} footer={false}>
        <Typography.Title level={4}>Зачислить на курс</Typography.Title>
        <Form onFinish={onFinish} layout={'vertical'} initialValues={{ id, course_id, group_id }}>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <FormGroupsList
            name={'group_id'}
            label={'Выберите группу'}
            mode={'single'}
            required={true}
          />
          <FormCoursesList name={'course_id'} label={'Курс'} required={true} />
          <MyDatePicker name={'added_at'} label={'chooseAddDate'} />
          <Form.Item>
            <Button
              type={'primary'}
              block
              disabled={isLoading}
              loading={isLoading}
              htmlType={'submit'}
            >
              Отправить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default ApplicationEnrolled
