import React from 'react'
import NewOrder from '../../app/modules/new-order/new-order'

interface props {}

export const NewOrderPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <NewOrder />
    </React.Fragment>
  )
}
