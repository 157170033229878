import React from 'react'
import ErrorAlert from '../../core/error-alert/error-alert'
import { Button, message, Skeleton, Space } from 'antd'
import Head from '../../../hoc/head/head'
import { APPLICATIONS_KEY, useApplications } from '../../../hooks/query/application'
import styles from './new-leads.module.scss'
import LeadsColumns from './leads-columns/leads-columns'
import LeadsSettings from './leads-settings/leads-settings'
import CreateApplication from './leads-bids/leads-bid/create-application/create-application'
import { BarChartOutlined, ReloadOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import { adminAccess, receptionAccess } from '../../../routes'
import SearchInputHandler from '../../core/search-input-handler/search-input-handler'
import LeadsEnrollmentsList from './leads-enrollments-list/leads-enrollments-list'

interface props {}

const NewLeads: React.FC<props> = () => {
  const history = useHistory()
  const { roleChecker } = useRoleChecker()
  const { isLoading, isFetching, isError, error, data, refetch } = useApplications({
    refetchInterval: 30000,
    onSuccess: () => {
      message.success('Список заявок был обновлен')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const statisticPage = () => history.push('/leads/statistics')

  const handleRefetch = () => refetch()

  return (
    <Head title={'Лиды (новые)'}>
      <div className={styles.header}>
        <SearchInputHandler
          searchName={'search'}
          placeholder={'Поиск по имени и номеру телефона'}
          fetchingQueryKey={APPLICATIONS_KEY}
        />
        <Space size={16}>
          <Button
            onClick={handleRefetch}
            disabled={isFetching}
            loading={isFetching}
            icon={<ReloadOutlined />}
          >
            Обновить список заявок
          </Button>
          {roleChecker(adminAccess) && (
            <Button onClick={statisticPage} icon={<BarChartOutlined />}>
              Статистика
            </Button>
          )}
          {roleChecker(receptionAccess) && (
            <React.Fragment>
              <CreateApplication />
              <LeadsSettings />
            </React.Fragment>
          )}
        </Space>
      </div>
      <LeadsEnrollmentsList />
      {data && <LeadsColumns columns={data} />}
    </Head>
  )
}

export default NewLeads
