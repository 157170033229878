import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import styles from '../new-order.module.scss'
import PhoneInput from '../../../core/form-group/phone-input/phone-input'
import { useMutation } from 'react-query'
import { newPublicApplication } from '../../../../api/applications'
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

interface props {
  changeIsSuccess: () => void
}

declare global {
  interface Window {
    grecaptcha: any
  }
}

const NewOrderForm: React.FC<props> = ({ changeIsSuccess }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const location = useLocation()
  const qs = queryString.parse(location.search)
  const { isLoading, mutate } = useMutation(newPublicApplication, {
    onSuccess: () => {
      changeIsSuccess()
    },
    onError: () => {
      message.error('Ошибка обработки заявки! Попробуйте снова')
    },
  })

  const onFinish = async (data: any) => {
    const token = await executeRecaptcha?.('submit')
    mutate({
      name: data.name,
      phone: data.phone,
      source_id: data.source_id,
      recaptcha_token: token ?? '',
    })
  }

  return (
    <>
      <h2>Оставить заявку в учебный центр</h2>
      <Form onFinish={onFinish} className={styles.form} layout={'vertical'}>
        <Form.Item name={'name'} label={'Ваше имя'} rules={[{ required: true }]}>
          <Input placeholder={'Введите имя'} />
        </Form.Item>
        <PhoneInput label={'Номер телефона'} />
        <Form.Item name={'source_id'} hidden initialValue={qs['source_id']}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
            disabled={isLoading}
          >
            Отправить заявку
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default NewOrderForm
