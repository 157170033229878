import React from 'react'
import StudentsPage from './pages/students/students-page'
import DashboardPage from './pages/dashboard/dashboard-page'
import LeadsPage from './pages/leads/leads-page'
import StudentPage from './pages/students/student/student-page'
import GroupsPage from './pages/groups/groups-page'
import GroupPage from './pages/groups/group/group-page'
import CoursesPage from './pages/courses/courses-page'
import CoursePage from './pages/courses/course/course-page'
import TeachersPage from './pages/teachers/teachers-page'
import TeacherPage from './pages/teachers/teacher/teacher-page'
import FinancesPage from './pages/finances/finances-page'

import FinancesGroupsPage from './pages/finances/finances-groups/finances-groups-page'
import FinancesGroupPage from './pages/finances/finances-groups/finances-group/finances-group-page'
import FinancesTeachersPage from './pages/finances/finances-teachers/finances-teachers-page'
import FinancesTeacherPage from './pages/finances/finances-teachers/finances-teacher/finances-teacher-page'
import FinancesDebtorsPage from './pages/finances/finances-debtors/finances-debtors-page'
import UsersPage from './pages/users/users-page'
import UserPage from './pages/users/user/user-page'
import RoomsPage from './pages/rooms/rooms-page'
import RoomPage from './pages/rooms/room/room-page'
import ProfilePage from './pages/profile/profile-page'
import AuthPage from './pages/auth/auth-page'
import EventsPage from './pages/testing/events/events-page'
import TestsPage from './pages/testing/tests/tests-page'
import TestPage from './pages/testing/tests/test-page'
import EventPage from './pages/testing/events/event-page'
import LatestResultPage from './pages/testing/result/latest-result-page'
import ResultPage from './pages/testing/result/result-page'
import { STUDENTS_KEY } from './hooks/query/students'
import { GROUPS_FINANCES_KEY, GROUPS_KEY } from './hooks/query/groups'
import { TEST_EVENTS_KEY } from './hooks/query/events'
import { QUESTION_BASES_KEY } from './hooks/query/question-bases'
import { COURSES_KEY } from './hooks/query/courses'
import { TEACHERS_FINANCES_KEY, TEACHERS_KEY } from './hooks/query/teachers'
import { USERS_KEY } from './hooks/query/users'
import { ROOMS_KEY } from './hooks/query/rooms'
import { GET_TEST_RESULT_KEY } from './hooks/query/test-session'
import { GET_LATEST_TEST_RESULT_KEY } from './hooks/query/testing'
import PaymentsHistoryPage from './pages/finances/payments-history/payments-history-page'
import PaymentsToTeachersPage from './pages/finances/payments-to-teachers/payments-to-teachers-page'
import BranchesPage from './pages/branches/branches-page'
import { BRANCHES_KEY } from './hooks/query/branches'
import BranchPage from './pages/branches/branch/branch-page'
import AdditionalExpensesPage from './pages/finances/additional-expenses/additional-expenses-page'
import ExpensesTypesPage from './pages/references/expenses-types-page/expenses-types-page'
import ArchivePage from './pages/archive/archive-page'
import ArchiveStuffPage from './pages/archive/stuff/archive-stuff-page'
import NewLeadsPage from './pages/new-leads/new-leads-page'
import { LeadsStatisticPage } from './pages/new-leads/leads-statistic/leads-statistic-page'
import { NewOrderPage } from './pages/new-order/new-order-page'

export const studentsAccess = ['admin', 'teacher', 'reception', 'cashier', 'accountant']
export const receptionAccess = ['admin', 'reception']
export const groupsAccess = ['admin', 'teacher', 'reception', 'cashier', 'student', 'accountant']
export const eventsAccess = ['admin', 'teacher', 'student', 'reception']
export const testsAccess = ['admin', 'teacher']
export const financesAccess = ['admin', 'cashier', 'accountant']
export const referencesAccess = ['admin', 'cashier', 'accountant', 'reception']
export const coursesAndTeachersAccess = ['admin', 'reception', 'cashier', 'accountant']
export const adminAccess = ['admin']

export interface IPrivateRoute {
  path: string
  breadcrumb: { breadcrumbName: string; fetchingQueryKey?: string }
  roles?: string[]
  component: React.FC<any>
}

const dashboardRoutes = [
  {
    path: '/dashboard',
    breadcrumb: {
      breadcrumbName: 'dashboard',
    },
    roles: receptionAccess,
    component: DashboardPage,
  },
]
const leadsRoutes = [
  {
    path: '/leads',
    breadcrumb: {
      breadcrumbName: 'leads',
    },
    roles: receptionAccess,
    component: LeadsPage,
  },
  {
    path: '/leads/statistics',
    breadcrumb: {
      breadcrumbName: 'statistics',
    },
    roles: adminAccess,
    component: LeadsStatisticPage,
  },
  {
    path: '/leads/new',
    breadcrumb: {
      breadcrumbName: 'leads',
    },
    roles: referencesAccess,
    component: NewLeadsPage,
  },
]
const studentsRoutes = [
  {
    path: '/students',
    breadcrumb: {
      breadcrumbName: 'students',
    },
    roles: studentsAccess,
    component: StudentsPage,
  },
  {
    path: '/students/details/:id',
    breadcrumb: {
      breadcrumbName: 'name',
      fetchingQueryKey: STUDENTS_KEY,
    },
    roles: studentsAccess,
    component: StudentPage,
  },
]
const groupsRoutes = [
  {
    path: '/groups',
    breadcrumb: {
      breadcrumbName: 'groups',
    },
    roles: groupsAccess,
    component: GroupsPage,
  },
  {
    path: '/groups/details/:id',
    breadcrumb: {
      breadcrumbName: 'name',
      fetchingQueryKey: GROUPS_KEY,
    },
    roles: groupsAccess,
    component: GroupPage,
  },
]
const testingRoutes = [
  {
    path: '/testing/events',
    breadcrumb: {
      breadcrumbName: 'events',
    },
    roles: eventsAccess,
    component: EventsPage,
  },
  {
    path: '/testing/events/details/:id',
    breadcrumb: {
      breadcrumbName: 'title',
      fetchingQueryKey: TEST_EVENTS_KEY,
    },
    roles: eventsAccess,
    component: EventPage,
  },

  {
    path: '/testing/tests',
    breadcrumb: {
      breadcrumbName: 'tests',
    },
    roles: testsAccess,
    component: TestsPage,
  },
  {
    path: '/testing/tests/details/:id',
    breadcrumb: {
      breadcrumbName: 'title',
      fetchingQueryKey: QUESTION_BASES_KEY,
    },
    roles: testsAccess,
    component: TestPage,
  },

  {
    path: '/testing/results/details/:id',
    breadcrumb: {
      breadcrumbName: 'user',
      fetchingQueryKey: GET_TEST_RESULT_KEY,
    },
    roles: eventsAccess,
    component: ResultPage,
  },
  {
    path: '/testing/results/test/:id',
    breadcrumb: {
      breadcrumbName: 'user',
      fetchingQueryKey: GET_LATEST_TEST_RESULT_KEY,
    },
    roles: eventsAccess,
    component: LatestResultPage,
  },
]
const coursesRoutes = [
  {
    path: '/courses',
    breadcrumb: {
      breadcrumbName: 'courses',
    },
    roles: coursesAndTeachersAccess,
    component: CoursesPage,
  },
  {
    path: '/courses/details/:id',
    breadcrumb: {
      breadcrumbName: 'name',
      fetchingQueryKey: COURSES_KEY,
    },
    roles: coursesAndTeachersAccess,
    component: CoursePage,
  },
]
const teachersRoutes = [
  {
    path: '/teachers',
    breadcrumb: {
      breadcrumbName: 'teachers',
    },
    component: TeachersPage,
    roles: coursesAndTeachersAccess,
  },
  {
    path: '/teachers/details/:id',
    breadcrumb: {
      breadcrumbName: 'name',
      fetchingQueryKey: TEACHERS_KEY,
    },
    roles: coursesAndTeachersAccess,
    component: TeacherPage,
  },
]
const financesRoutes = [
  {
    path: '/finances',
    breadcrumb: {
      breadcrumbName: 'finances',
    },
    component: FinancesPage,
    roles: financesAccess,
  },

  {
    path: '/finances/groups',
    breadcrumb: {
      breadcrumbName: 'groups',
    },
    roles: financesAccess,
    component: FinancesGroupsPage,
  },
  {
    path: '/finances/groups/:id',
    roles: financesAccess,
    breadcrumb: {
      breadcrumbName: 'group',
      fetchingQueryKey: GROUPS_FINANCES_KEY,
    },
    component: FinancesGroupPage,
  },
  {
    path: '/finances/teachers',
    breadcrumb: {
      breadcrumbName: 'teachers',
    },
    roles: financesAccess,
    component: FinancesTeachersPage,
  },
  {
    path: '/finances/teachers/:id',
    breadcrumb: {
      breadcrumbName: 'teacher',
      fetchingQueryKey: TEACHERS_FINANCES_KEY,
    },
    roles: financesAccess,
    component: FinancesTeacherPage,
  },
  {
    path: '/finances/debtors',
    breadcrumb: {
      breadcrumbName: 'debtors',
    },
    roles: financesAccess,
    component: FinancesDebtorsPage,
  },
  {
    path: '/finances/payments-history',
    breadcrumb: {
      breadcrumbName: 'paymentsHistory',
    },
    roles: financesAccess,
    component: PaymentsHistoryPage,
  },
  {
    path: '/finances/payments-to-teachers',
    breadcrumb: {
      breadcrumbName: 'paymentsToTeachers',
    },
    roles: financesAccess,
    component: PaymentsToTeachersPage,
  },
  {
    path: '/finances/additional-expenses',
    breadcrumb: {
      breadcrumbName: 'additionalExpenses',
    },
    roles: financesAccess,
    component: AdditionalExpensesPage,
  },
]
const staffRoutes = [
  {
    path: '/staff',
    breadcrumb: {
      breadcrumbName: 'staff',
    },
    roles: adminAccess,
    component: UsersPage,
  },
  {
    path: '/profile/details/:id',
    breadcrumb: {
      breadcrumbName: 'name',
      fetchingQueryKey: USERS_KEY,
    },
    roles: adminAccess,
    component: UserPage,
  },
]
const roomsRoutes = [
  {
    path: '/rooms',
    breadcrumb: {
      breadcrumbName: 'rooms',
    },
    roles: adminAccess,
    component: RoomsPage,
  },
  {
    path: '/rooms/details/:id',
    breadcrumb: {
      breadcrumbName: 'name',
      fetchingQueryKey: ROOMS_KEY,
    },
    roles: adminAccess,
    component: RoomPage,
  },
]
const profileRoutes = [
  {
    path: '/profile',
    breadcrumb: {
      breadcrumbName: 'profile',
    },
    component: ProfilePage,
  },
]
const branchesRoutes = [
  {
    path: '/branches',
    breadcrumb: {
      breadcrumbName: 'branches',
    },
    roles: adminAccess,
    component: BranchesPage,
  },
  {
    path: '/branches/details/:id',
    breadcrumb: {
      breadcrumbName: 'title',
      fetchingQueryKey: BRANCHES_KEY,
    },
    roles: adminAccess,
    component: BranchPage,
  },
]
const referencesRoutes = [
  {
    path: '/references/expenses',
    roles: financesAccess,
    breadcrumb: {
      breadcrumbName: 'expenses',
    },
    component: ExpensesTypesPage,
  },
]
const archiveRoutes = [
  {
    path: '/archive',
    roles: receptionAccess,
    breadcrumb: {
      breadcrumbName: 'archive',
    },
    component: ArchivePage,
  },
  {
    path: '/archive/stuff',
    roles: receptionAccess,
    breadcrumb: {
      breadcrumbName: 'stuffArchive',
    },
    component: ArchiveStuffPage,
  },
]

export const privateRoutes: IPrivateRoute[] = [
  ...dashboardRoutes,
  ...leadsRoutes,
  ...studentsRoutes,
  ...groupsRoutes,
  ...testingRoutes,
  ...coursesRoutes,
  ...teachersRoutes,
  ...financesRoutes,
  ...staffRoutes,
  ...roomsRoutes,
  ...profileRoutes,
  ...branchesRoutes,
  ...referencesRoutes,
  ...archiveRoutes,
]

export const publicRoutes = [
  {
    path: '/auth',
    component: AuthPage,
  },
  {
    path: '/new-order',
    component: NewOrderPage,
  },
]
