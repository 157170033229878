import React, { useState } from 'react'
import Head from '../../../hoc/head/head'
import NewOrderForm from './new-order-form/new-order-form'
import NewOrderSuccess from './new-order-success/new-order-success'

interface props {}

const NewOrder: React.FC<props> = () => {
  const [isSuccess, setIsSucess] = useState<boolean>(false)
  const handleChangeIsSuccess = () => setIsSucess(true)

  const title = 'Новая заявка'
  return (
    <Head title={title}>
      {isSuccess ? <NewOrderSuccess /> : <NewOrderForm changeIsSuccess={handleChangeIsSuccess} />}
    </Head>
  )
}

export default NewOrder
