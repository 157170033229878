import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IEnrollment } from '../../interfaces'
import { getAllEnrollments } from '../../api/enrollments'

export const ENROLLMENTS_KEY = 'ENROLLMENTS_KEY'

export function useEnrollmentsList(options?: UseQueryOptions<IEnrollment[], Error>) {
  return useQuery<IEnrollment[], Error>(
    ENROLLMENTS_KEY,
    () => getAllEnrollments().then((response: AxiosResponse<IEnrollment[]>) => response.data),
    {
      ...options,
    },
  )
}
