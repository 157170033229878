import React, { useState } from 'react'
import { IGroupActivity, IGroupStudentAttend } from '../../../../../interfaces'
import { Button, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { createAttendanceApi, updateAttendanceApi } from '../../../../../api/groups'
import styles from './group-attendance-status.module.scss'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../../index'
import { GROUP_STUDENTS_ACTIVITY_KEY } from '../../../../../hooks/query/groups'
import { useRoleChecker } from '../../../../../hooks/useRoleChecker'
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import GroupAttendanceComment from '../group-attendance-comment/group-attendance-comment'
import GroupPopover from '../../group-popover/group-popover'
import GroupReasonAttendanceForm from '../group-reason-attendance-form/group-reason-attendance-form'
import isEqual from 'react-fast-compare'
import { idType } from '../../../../../interfaces/types/types'
import { AxiosResponse } from 'axios'

interface props {
  attendance: IGroupStudentAttend
  studentId: idType
  day: string
}

const GroupAttendanceStatus: React.FC<props> = ({ attendance, studentId, day }) => {
  const [reasonPopover, setReasonPopover] = useState<boolean>(false)
  const onPopoverVisibleChange = () => setReasonPopover(!reasonPopover)
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'groups'])
  const { roleChecker } = useRoleChecker()

  const commentAccess = roleChecker(['admin', 'teacher', 'reception'])
  const attendanceAccess = roleChecker(['admin', 'teacher'])
  const isAdmin = roleChecker(['admin'])

  const onSuccess = async (response: AxiosResponse<IGroupStudentAttend>) => {
    const oldQueryData = queryClient.getQueryData<IGroupActivity>([GROUP_STUDENTS_ACTIVITY_KEY, id])

    queryClient.setQueryData([GROUP_STUDENTS_ACTIVITY_KEY, id], {
      ...oldQueryData,
      students: oldQueryData?.students.map(student => {
        if (student.id === studentId) {
          return { ...student, attends: response.data }
        }
        return student
      }),
    })
    reasonPopover && onPopoverVisibleChange()
  }

  const onError = (e: any) => {
    message.error(e.response.data.message)
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createAttendanceApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateAttendanceApi, {
    onSuccess,
    onError,
  })

  const submitStatus = ({ status, comment }: any) => {
    const handler = !attendance ? create : update

    return handler({
      status,
      comment,
      date: day,
      group_id: id,
      student_id: studentId,
      id: attendance?.id,
    })
  }

  const statusButtons = [
    {
      value: 'not',
      icon: <CloseOutlined />,
      color: '#f50',
    },
    {
      value: 'was',
      icon: <CheckOutlined />,
      color: '#87d068',
    },
    {
      value: 'reason',
      icon: <ExclamationCircleOutlined />,
      color: '#2db7f5',
    },
  ]

  const renderTag = () => {
    return (
      <div className={styles.wrapper}>
        {statusButtons.map(({ value, icon, color }, index) => {
          const buttonProps = {
            key: index,
            className: [styles.button, attendance?.status === value ? styles.active : ''].join(' '),
            style: { backgroundColor: attendance?.status === value ? color : '' },
            icon,
            loading: isCreateLoading || isUpdateLoading,
          }

          if (value === 'reason') {
            if (isAdmin) {
              return (
                <GroupPopover
                  key={index}
                  title={t('describeAGoodReason')}
                  content={
                    <GroupReasonAttendanceForm
                      submitStatus={submitStatus}
                      comment={attendance?.comment}
                    />
                  }
                  day={day}
                  visible={reasonPopover}
                  onChange={onPopoverVisibleChange}
                >
                  <Button {...buttonProps} size={'small'}>
                    {attendance?.comment && commentAccess ? (
                      <GroupAttendanceComment comment={attendance.comment} />
                    ) : null}
                  </Button>
                </GroupPopover>
              )
            }
          } else {
            return (
              <Button
                {...buttonProps}
                size={'small'}
                onClick={() => attendanceAccess && submitStatus({ comment: '', status: value })}
              />
            )
          }
        })}
      </div>
    )
  }

  return <React.Fragment>{renderTag()}</React.Fragment>
}

const propAreEqual = (prevProps: props, nextProps: props) => {
  return (
    isEqual(prevProps.attendance, nextProps.attendance) &&
    prevProps.day === nextProps.day &&
    prevProps.studentId === nextProps.studentId
  )
}

export default React.memo(GroupAttendanceStatus, propAreEqual)
