import React from 'react'
import { PageHeader, Skeleton } from 'antd'
import SourcesDrawer from './sources-drawer/sources-drawer'
import SourcesTable from './sources-table/sources-table'
import { useAllSources } from '../../../../../hooks/query/sources'
import ErrorAlert from '../../../../core/error-alert/error-alert'

interface props {}

const Sources: React.FC<props> = () => {
  const { data, isLoading, isError, error } = useAllSources()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  return (
    <>
      <PageHeader title={'Источники'} extra={[<SourcesDrawer key={'1'} />]} />
      <SourcesTable data={data} />
    </>
  )
}

export default Sources
