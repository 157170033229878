import React from 'react'
import { Form, Select } from 'antd'
import { IEnrollment } from '../../../interfaces'
import { filterOption } from '../../../lib/filterOption'
import { useEnrollmentsList } from '../../../hooks/query/enrollments'

interface props {
  required?: boolean
  name?: string
}

const FormEnrollmentsList: React.FC<props> = ({ required, name = 'enrollment_id' }) => {
  const { data, isLoading } = useEnrollmentsList()

  return (
    <Form.Item name={name} label={'Наборы'} rules={[{ required }]}>
      <Select
        disabled={isLoading}
        loading={isLoading}
        showSearch
        placeholder={'Выберите набор'}
        filterOption={filterOption}
      >
        {data?.map(({ id, name }: IEnrollment) => (
          <Select.Option key={id} label={name} value={id!}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormEnrollmentsList
