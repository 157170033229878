import React from 'react'
import { Result } from 'antd'
import styles from './new-order-success.module.scss'

interface props {}

const NewOrderSuccess: React.FC<props> = () => {
  return (
    <Result
      className={styles.result}
      status="success"
      title="Ваша заявка успешно принята!"
      subTitle="В ближайшее время наш администратор обязательно с вами свяжется."
    />
  )
}

export default NewOrderSuccess
