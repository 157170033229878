import React, { useState } from 'react'
import GroupMonthPicker from '../group-month-picker/group-month-picker'
import GroupActivity from '../group-activity/group-activity'
import GroupNotifications from '../group-notifications/group-notifications'
import { Card, Divider, Space, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import GroupStudents from '../group-students/group-students'
import GroupAddStudents from '../group-add-students/group-add-students'
import { getQueryParam } from '../../../../lib/getQueryParam'
import { serialize } from '../../../../lib/serializeQuery'
import { useHistory } from 'react-router-dom'
import GroupDiscounts from '../group-discounts/group-discounts'
import GroupDiscountModal from '../group-discounts/group-discount-modal/group-discount-modal'
import GroupEvent from '../group-event/group-event'

const { TabPane } = Tabs

interface props {
  start_date: string
  end_date: string
}

type tabKey = 'students' | 'attends_and_grades' | 'notifications' | 'individual-price' | 'discounts'

const GroupTabs: React.FC<props> = ({ start_date, end_date }) => {
  const [activeKey, setActiveKey] = useState<tabKey>((getQueryParam('tab') as tabKey) || 'students')
  const { replace } = useHistory()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])
  const { t } = useTranslation(['translation', 'groups'])

  const handleTabChange = (key: string) => {
    replace({
      search: serialize('tab', key),
    })

    setActiveKey(key as tabKey)
  }

  const renderExtraContent = () => {
    // if (activeKey === 'students' && access) return <GroupAddStudents />
    if (activeKey === 'attends_and_grades') {
      return <GroupMonthPicker end_date={end_date} start_date={start_date} />
    }
    if (activeKey === 'discounts' && access) {
      return <GroupDiscountModal groupStartDate={start_date} groupEndDate={end_date} />
    }

    return null
  }

  return (
    <Card bordered={false}>
      <Tabs
        onChange={handleTabChange}
        defaultActiveKey={activeKey}
        tabBarExtraContent={renderExtraContent()}
        destroyInactiveTabPane={true}
      >
        <TabPane tab={t('groups:studentList')} key={'students' as tabKey}>
          <GroupStudents groupStartDate={start_date} groupEndDate={end_date} />
        </TabPane>
        <TabPane
          tab={
            <Space split={<Divider type={'vertical'} />}>
              <span>{t('attendance')}</span>
              <span>{t('grades')}</span>
            </Space>
          }
          key={'attends_and_grades' as tabKey}
        >
          <GroupActivity />
        </TabPane>
        <TabPane tab={t('notifications')} key={'notifications' as tabKey}>
          <GroupNotifications />
        </TabPane>
        {access && (
          <TabPane tab={t('discounts')} key={'discounts' as tabKey}>
            <GroupDiscounts groupStartDate={start_date} groupEndDate={end_date} />
          </TabPane>
        )}
        <TabPane tab={t('testing')} key={'testing'}>
          <GroupEvent />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default React.memo(GroupTabs)
