import React, { useState } from 'react'
import { Button, Card, Drawer, Form, Input, message, Typography } from 'antd'
import { useMutation } from 'react-query'
import { IApplication } from '../../../../../../interfaces'
import { queryClient } from '../../../../../../index'
import { APPLICATIONS_KEY } from '../../../../../../hooks/query/application'
import FormPaymentMethods from '../../../../../core/form-payment-methods/form-payment-methods'
import SumInput from '../../../../../core/form-group/sum-input/sum-input'
import MyDatePicker from '../../../../../core/form-group/my-date-picker/my-date-picker'
import {
  CheckCircleOutlined,
  PlusCircleOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons'
import DrawerFooter from '../../../../../core/drawer/drawer-footer/drawer-footer'
import { pay } from '../../../../../../api/applications'
import { drawerSettings } from '../../../../../../lib/settings'

interface props {
  application: IApplication
}

const AddPayment: React.FC<props> = ({ application }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => setVisible(!visible)
  const [form] = Form.useForm()

  const onSuccess = async () => {
    drawerToggle()
    form.resetFields()
    queryClient.invalidateQueries(APPLICATIONS_KEY)
  }
  const onError = (e: any) => {
    message.error(e.response.data.message)
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(pay, {
    onSuccess,
    onError,
  })

  const submitForm = (fieldsValues: any) => create(fieldsValues)

  return (
    <>
      <Button size={'small'} onClick={drawerToggle} icon={<WalletOutlined />} block type={'link'}>
        Добавить оплату
      </Button>
      <Drawer
        {...drawerSettings}
        destroyOnClose={true}
        onClose={drawerToggle}
        visible={visible}
        title={'Добавить оплату'}
        footer={
          <DrawerFooter
            disabled={isCreateLoading}
            drawerToggle={drawerToggle}
            formId={'application-payments-form'}
          />
        }
      >
        <Form
          form={form}
          id={'application-payments-form'}
          onFinish={submitForm}
          layout={'vertical'}
          initialValues={application}
        >
          <div
            style={{
              background: '#fafafa',
              padding: '15px 10px',
              borderRadius: '5px',
              marginBottom: 12,
            }}
          >
            <Typography.Title level={5}>
              <UserOutlined /> Лид
            </Typography.Title>
            <Card size={'small'} bordered={false}>
              <div>
                <Typography.Text>
                  <strong>Имя:</strong> {application.lead.name}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text>
                  <strong>Курс:</strong> {application.course?.name}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text>
                  <strong>Источник:</strong> {application.lead.source?.name ?? 'Неизвестно'}
                </Typography.Text>
              </div>
            </Card>
          </div>
          <Form.Item name={'id'} hidden={true}>
            <Input />
          </Form.Item>
          <SumInput name={'amount'} label={'Сумма'} />
          <FormPaymentMethods />
          <MyDatePicker
            name={'date'}
            label={'Дата платежа'}
            required={true}
            format={'YYYY-MM-DD'}
          />

          <Form.Item name="comment" label={'Комментарий'}>
            <Input.TextArea placeholder={'Введите комментарий'} rows={6} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddPayment
