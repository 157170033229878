import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import {
  IAddStudentToGroup,
  IGroup,
  IGroupNotification,
  IGroupStudentAttend,
  IGroupStudentGrade,
} from '../interfaces'

export function getAllGroupsApi() {
  return axios.get('/groups')
}

export function getGroupsListApi() {
  return axios.get('/groups/list')
}

export function getGroupByIdApi(id: idType) {
  return axios.get(`/groups/${id}`)
}

export function getGroupStudentsApi(id: idType) {
  return axios.get(`/groups/${id}/students`)
}

export function getGroupNotificationsApi(id: idType) {
  return axios.get(`/groups/${id}/notifications`)
}

export function getGroupStudentsActivityApi(id: idType) {
  return axios.get(`groups/${id}/activity`)
}

export function createGroupApi(group: IGroup) {
  return axios.post('/groups', group)
}

export function updateGroupApi(group: IGroup) {
  return axios.patch(`/groups/${group.id}`, group)
}

export function deleteGroupApi(id: idType) {
  return axios.delete(`/groups/${id}`)
}

export function postStudentsToGroupApi({ id, data }: { id: idType; data: IAddStudentToGroup[] }) {
  return axios.post(`/groups/${id}/add-students`, {
    students: data,
  })
}

export function removeStudentFromGroupApi(data: { groupId: idType; studentId: idType }) {
  return axios.delete(`/groups/${data.groupId}/remove-student/${data.studentId}`, {
    data: {
      delete_attendances_grades: true,
      delete_debits_payments: true,
    },
  })
}

export function createAttendanceApi(att: IGroupStudentAttend) {
  return axios.post('/attendances', att)
}

export function updateAttendanceApi(att: IGroupStudentAttend) {
  return axios.patch(`/attendances/${att.id}`, att)
}

export function createNotificationApi(data: IGroupNotification) {
  return axios.post('/notifications', data)
}

export function createGradeApi(data: IGroupStudentGrade) {
  return axios.post('/grades', data)
}

export function updateGradeApi(data: IGroupStudentGrade) {
  return axios.patch(`/grades/${data.id}`, data)
}

export function setIndividualPriceForStudentApi({
  studentId,
  price,
  groupId,
  set_course_price,
}: {
  groupId: idType
  studentId: idType
  price: number
  set_course_price: boolean
}) {
  return axios.patch(`/groups/${groupId}/individual-price/${studentId}`, {
    price,
    group_id: groupId,
    student_id: studentId,
    set_course_price,
  })
}

export function groupFinanceRecalculationApi(group_id: idType) {
  return axios.post('/recalculate/group/' + group_id)
}

export function groupStudentsSortApi({ groupId, students }: { groupId: idType; students: any }) {
  return axios.patch('/groups/' + groupId + '/update-sort', {
    sort: students,
  })
}

export function excludeStudentFromGroupApi(data: {
  groupId: idType
  studentId: idType
  date: string
  rejection_reason_id: idType
}) {
  return axios.post('/groups/' + data.groupId + '/exclude/' + data.studentId, {
    date: data.date,
    rejection_reason_id: data.rejection_reason_id,
  })
}

export function transferStudentApi(data: {
  from: idType
  to: idType
  studentId: idType
  date: string
}) {
  return axios.post('/groups/transfer', {
    student_id: data.studentId,
    from_group_id: data.from,
    to_group_id: data.to,
    transferred_at: data.date,
  })
}

// BULK ACTIONS

export function removeStudentsFromGroupApi(data: { groupId: idType; ids: idType[] }) {
  return axios.delete('/groups/' + data.groupId + '/remove-students', {
    data: {
      student_ids: data.ids,
    },
  })
}

// DISCOUNTS

export function getGroupDiscounts(groupId: idType) {
  return axios.get('/groups/' + groupId + '/discounts')
}

export function getGroupEventsApi(id: idType) {
  return axios.get('/groups/' + id + '/tests')
}

export function getGroupEventStudentsResultsApi(data: { groupId: idType; testId: idType }) {
  return axios.get('/groups/' + data.groupId + '/tests/' + data.testId + '/results')
}
