import React from 'react'
import styles from './leads-bid.module.scss'
import { Card, Space, Typography } from 'antd'
import {
  BookOutlined,
  CalendarOutlined,
  CloseCircleTwoTone,
  PhoneOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { IApplication } from '../../../../../interfaces'
import LeadsBidDropdown from './leads-bid-dropdown/leads-bid-dropdown'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import moment from 'moment'
import { Link } from 'react-router-dom'

interface props {
  item: IApplication
}

const LeadsBid = ({ item }: props) => {
  return (
    <Card className={styles.bid} bordered={false}>
      {item.enrollment && <div className={styles.enrollment}>{item.enrollment.name}</div>}
      <div className={styles.content}>
        <div className={styles.bidHeader}>
          <div>
            <UserOutlined /> {item.lead.name}
          </div>
          <span className={styles.createdAt}>
            <CalendarOutlined /> {moment(item.updated_at).format('DD-MM-YYYY HH:mm')}
          </span>
        </div>
        <div className={styles.bidMain}>
          <div>
            <PhoneOutlined /> {item.lead.phone}
          </div>
          {item.course ? (
            <div>
              <BookOutlined /> {item.course.name}
            </div>
          ) : null}

          {item.group ? (
            <div>
              <Space>
                <div>
                  <UsergroupAddOutlined /> {item.group.name}
                </div>
                <Link to={'/groups/details/' + item.group.id}>
                  <Typography.Text type={'secondary'}>(Перейти)</Typography.Text>
                </Link>
              </Space>
            </div>
          ) : null}
          {item.rejection_reason ? (
            <div>
              <CloseCircleTwoTone twoToneColor={'red'} /> {item.rejection_reason.name}
            </div>
          ) : null}
        </div>

        <div className={styles.bidFooter}>
          <div className={styles.source}>
            {item.lead.source?.name ?? 'Неизвестно'}
            <div
              className={styles.sourceColor}
              style={{ backgroundColor: item.lead.source?.color }}
            />
          </div>

          <div className={styles.additional}>{priceFormatter(item.balance)}</div>
        </div>
      </div>
      {item.status.actions.length ? <LeadsBidDropdown item={item} /> : null}
    </Card>
  )
}

export default React.memo(LeadsBid)
