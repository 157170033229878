import React from 'react'
import { IApplication } from '../../../../interfaces'
import LeadsBid from './leads-bid/leads-bid'

interface props {
  bids: IApplication[]
}

const LeadsBids = ({ bids }: props) => {
  return (
    <React.Fragment>
      {bids.map((item, index) => (
        <LeadsBid key={index} item={item} />
      ))}
    </React.Fragment>
  )
}

export default React.memo(LeadsBids)
