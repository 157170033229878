import { Form, Select } from 'antd'
import { useAllSources } from '../../../../hooks/query/sources'

interface ISelectSourceProps {
  name?: string
  required?: boolean
  label?: string
}
export const SelectSource: React.FC<ISelectSourceProps> = ({ name, required, label }) => {
  const { data, isLoading } = useAllSources()
  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <Select placeholder={'Выберите источник'} loading={isLoading} disabled={isLoading}>
        {data?.map(source => (
          <Select.Option key={source.id} value={source.id?.toString() ?? ''}>
            {source.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
