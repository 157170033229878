import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './leads-enrollments-list.module.scss'
import { useEnrollmentsList } from '../../../../hooks/query/enrollments'
import { Button, Form, Input, message, Modal } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { createEnrollment } from '../../../../api/enrollments'
import { serialize } from '../../../../lib/serializeQuery'
import { queryClient } from '../../../../index'
import { APPLICATIONS_KEY } from '../../../../hooks/query/application'
import queryString from 'query-string'
import { idType } from '../../../../interfaces/types/types'

interface props {}

const NewLeads: React.FC<props> = () => {
  const [create, setCreate] = useState(false)
  const toggleCreate = () => setCreate(!create)
  const history = useHistory()
  const location = useLocation()
  const qs = queryString.parse(location.search)
  const qsEnrollmentId = qs['enrollment_id']

  const { data, refetch } = useEnrollmentsList()

  const createFn = useMutation(createEnrollment, {
    onSuccess: () => {
      refetch()
      setCreate(false)
    },
    onError: () => {
      message.error('Ошибка создания! Попробуйте снова')
    },
  })

  const handleFilter = async (id: idType) => {
    history.push({
      pathname: location.pathname,
      search: serialize('enrollment_id', qsEnrollmentId === id?.toString() ? '' : id),
    })

    queryClient.invalidateQueries(APPLICATIONS_KEY)
  }

  const handleCreateEnrollment = (data: any) => createFn.mutate(data)

  return (
    <div className={styles.wrapper}>
      {data?.map(enrollment => {
        const onClick = () => handleFilter(enrollment.id)
        return (
          <Button
            key={enrollment.id}
            size={'small'}
            onClick={onClick}
            type={qsEnrollmentId === enrollment.id?.toString() ? 'primary' : 'default'}
          >
            {enrollment.name}
          </Button>
        )
      })}
      <Button size={'small'} icon={<PlusCircleOutlined />} onClick={toggleCreate} type={'ghost'}>
        Создать набор
      </Button>
      <Modal
        visible={create}
        onCancel={toggleCreate}
        title={'Создать набор'}
        okButtonProps={{ htmlType: 'submit', form: 'create-enrollment' }}
      >
        <Form id={'create-enrollment'} onFinish={handleCreateEnrollment} layout={'vertical'}>
          <Form.Item name={'name'} label={'Название набора'} rules={[{ required: true }]}>
            <Input placeholder={'Введите название набора'} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default NewLeads
