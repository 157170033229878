import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import {
  ICreateApplicationData,
  IUpdateApplicationData,
  NewPublicApplicationData,
} from '../interfaces'

const url = '/applications'

export function getAllApplications() {
  return axios.get(url)
}

export function getApplicationById(id: idType) {
  return axios.get(url + '/' + id)
}

export function createApplication(data: ICreateApplicationData) {
  return axios.post(url, data)
}

export function updateApplication(data: IUpdateApplicationData) {
  return axios.post(url + '/' + data.id + '/edit', data)
}

export function deleteBranch(id: idType) {
  return axios.delete(url + '/' + id)
}

export function startProcess(id: idType) {
  return axios.post(url + '/' + id + '/start-processing')
}

export function trialEnroll(id: idType) {
  return axios.post(url + '/' + id + '/trial-enrolled')
}

export function trialAttend(id: idType) {
  return axios.post(url + '/' + id + '/trial-attended')
}

export function paymentRequested(id: idType) {
  return axios.post(url + '/' + id + '/payment-requested')
}

export function paymentApproved(id: idType) {
  return axios.post(url + '/' + id + '/paid')
}

export function enrollApplication({
  id,
  course_id,
  group_id,
  added_at,
}: {
  id: idType
  course_id: idType
  group_id: idType
  added_at: string
}) {
  return axios.post(url + '/' + id + '/enrolled', { course_id, group_id, added_at })
}

export function rejectApplication({
  rejection_reason_id,
  id,
}: {
  id: idType
  rejection_reason_id: idType
}) {
  return axios.post(url + '/' + id + '/reject', { rejection_reason_id })
}

export function withdrawnApplication({
  rejection_reason_id,
  id,
}: {
  id: idType
  rejection_reason_id: idType
}) {
  return axios.post(url + '/' + id + '/withdrawn', { rejection_reason_id })
}

export function pay(data: any) {
  return axios.post(url + '/' + data.id + '/pay', data)
}

export function newPublicApplication(data: NewPublicApplicationData) {
  return axios.post(url + '/form', data)
}
