import React, { useState } from 'react'
import { Button, Modal, Typography } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { CreateApplicationForm } from './create-application-form'

interface props {}

const CreateApplication: React.FC<props> = () => {
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  return (
    <React.Fragment>
      <Button onClick={handleChangeVisible} icon={<PlusCircleOutlined />}>
        Создать заявку
      </Button>
      <Modal destroyOnClose visible={visible} onCancel={handleChangeVisible} footer={false}>
        <Typography.Title level={4}>Создать заявку</Typography.Title>
        <CreateApplicationForm onSuccess={handleChangeVisible} />
      </Modal>
    </React.Fragment>
  )
}

export default CreateApplication
