import { Form, FormInstance, FormItemProps, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorResult, TwitterPicker } from 'react-color'

interface props {
  name?: string
  label?: string
  required?: boolean
  formItemProps?: FormItemProps
  formInstance: FormInstance
}

const ColorPicker: React.FC<props> = ({
  name = 'color',
  label = 'color',
  required,
  formItemProps,
  formInstance,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const showPicker = () => handleVisibleChange(true)
  const hidePicker = () => handleVisibleChange(false)
  const handleVisibleChange = (v: boolean) => {
    const interval = setInterval(() => {
      setVisible(v)
      clearInterval(interval)
    }, 100)
  }
  const [color, setColor] = useState<string>('')
  const handleColorChange = (color: ColorResult): any => {
    setColor(color.hex)
    hidePicker()
    formInstance.setFieldsValue({ [name]: color.hex })
  }
  const { t } = useTranslation()

  return (
    <>
      <Form.Item name={name} label={t(label)} rules={[{ required }]} {...formItemProps}>
        <Input onFocus={showPicker} onBlur={hidePicker} value={color} />
      </Form.Item>
      {visible && (
        <TwitterPicker
          colors={[
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#cddc39',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#795548',
            '#607d8b',
          ]}
          onChange={handleColorChange}
          color={color}
        />
      )}
    </>
  )
}

export default ColorPicker
