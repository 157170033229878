import React from 'react'
import SalesFunnel from './sales-funnel/sales-funnel'
import { Col, Row } from 'antd'

interface props {}

export const LeadsStatistic: React.FC<props> = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <SalesFunnel />
      </Col>
    </Row>
  )
}
