import React from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { Button, message, Space, Table, Typography } from 'antd'
import SourcesDrawer from '../sources-drawer/sources-drawer'
import DeleteModal from '../../../../../core/delete-modal/delete-modal'
import { deleteBidApi } from '../../../../../../api/leads'
import { ISource } from '../../../../../../interfaces'
import { SOURCES_KEY } from '../../../../../../hooks/query/sources'
import { deleteSource } from '../../../../../../api/sources'
import ColorBlock from '../../../../../core/color-block/color-block'
import { CopyOutlined } from '@ant-design/icons'

interface props {
  data: ISource[] | undefined
}

const SourcesTable: React.FC<props> = ({ data }) => {
  const { t } = useTranslation()

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Источник',
      dataIndex: 'name',
      render: (text: string) => <Typography.Text strong>{text}</Typography.Text>,
    },
    {
      title: 'Цвет',
      dataIndex: 'color',
      render: color => <ColorBlock color={color} />,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Ссылка на источник',
      key: 'link',
      render: record => {
        const handleCopy = async () => {
          await navigator.clipboard.writeText(
            'https://cabinet.spmbio.uz/new-order?source_id=' + record.id,
          )
          message.success('Ссылка была успешно скопирована')
        }
        return (
          <Button icon={<CopyOutlined />} onClick={handleCopy} size={'small'}>
            Скопировать
          </Button>
        )
      },
    },
    {
      title: t('actions'),
      align: 'right',
      key: 'actions',
      render: (entity: any) => (
        <Space>
          <SourcesDrawer source={entity} />
        </Space>
      ),
    },
  ]

  return <Table dataSource={data} columns={columns} rowKey={'id'} />
}

export default SourcesTable
