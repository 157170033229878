import React, { ChangeEvent } from 'react'
import { Button, Input, message } from 'antd'
import { useMutation } from 'react-query'
import { addNewReason } from '../../../../api/rejection-reasons'
import { queryClient } from '../../../../index'
import { REJECTION_REASONS_LIST } from '../../../../hooks/query/rejection-reasons'

export const AddRejectReasonInput: React.FC<any> = () => {
  const [value, setValue] = React.useState('')
  const onChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)

  const { isLoading, mutate } = useMutation(addNewReason, {
    onSuccess: () => {
      queryClient.invalidateQueries(REJECTION_REASONS_LIST)
      message.success('Причина была успешно добавлена')
      setValue('')
    },
    onError: () => {
      message.error('Ошибка добавления причины')
    },
  })

  const handleAdd = () => mutate(value)

  return (
    <Input
      placeholder={'Введите название причины'}
      value={value}
      onChange={onChange}
      addonAfter={
        <Button
          onClick={handleAdd}
          size={'small'}
          type={'link'}
          disabled={!value.length}
          loading={isLoading}
        >
          Добавить
        </Button>
      }
    />
  )
}
